import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    customer: null,
    declaration: null,
    condensedDeclarations: [],
  },
  getters: {
    customer: (state) => state.customer,
    declaration: (state) => state.declaration,
    condensedDeclarations: (state) => state.condensedDeclarations,
  },
  mutations: {
    SET_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    SET_DECLARATION(state, declaration) {
      state.declaration = declaration;
    },
    SET_CONDENSED_DECLARATIONS(state, condensedDeclarations) {
      state.condensedDeclarations = condensedDeclarations;
    },
  },
  actions: {
    async fetchCustomer({ commit }, personalToken) {
      try {
        const response = await axios.get("/api/customer/customer", {
          params: { personalToken },
        });
        commit("SET_CUSTOMER", response.data);
      } catch (error) {
        console.error("Failed to fetch customer:", error);
      }
    },
    async fetchDeclaration({ commit }, { id, personalToken }) {
      try {
        const response = await axios.get(
          `/api/declarations/declaration/${id}`,
          {
            params: { personalToken },
          }
        );
        commit("SET_DECLARATION", response.data);
      } catch (error) {
        console.error("Failed to fetch declaration:", error);
      }
    },
    async postDeclaration({ commit }, declaration) {
      try {
        const response = await axios.post(
          "/api/declarations/declaration",
          declaration
        );
        commit("SET_DECLARATION", response.data);
      } catch (error) {
        console.error("Failed to post declaration:", error);
      }
    },
    async fetchCondensedDeclarations({ commit }, personalToken) {
      try {
        const response = await axios.get("/api/declarations/condensed", {
          params: { personalToken },
        });
        commit("SET_CONDENSED_DECLARATIONS", response.data);
      } catch (error) {
        console.error("Failed to fetch condensed declarations:", error);
      }
    },
  },
});
