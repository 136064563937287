<template>
  <router-view />
</template>

<style>
/* VUETIFY overrides */
.v-field--disabled {
  opacity: 0.6 !important;
}

.v-checkbox .v-input__details {
  display: none;
}

.v-field:has(input[required]) .v-label::after {
  content: "*";
  padding-left: 4px;
}
</style>

<style>
#sheet {
  width: 80%;
}

#form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 3px solid var(--primary-color);
  padding: 14px;
}

#form .form-block-half {
  width: 48%;
}

#form .form-block-full {
  width: 100%;
}

#form .form-block-title {
  background-color: var(--primary-color);
  color: var(--white-color);
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  padding: 4px 12px;
  margin-bottom: 14px;
}

@media only screen and (max-width: 768px) {
  #sheet {
    width: 100%;
  }

  #form .form-block-half {
    width: 100%;
  }
}
</style>

<style>
* {
  box-sizing: border-box;
}
:root {
  --primary-color: rgb(1, 102, 179);
  --black-color: rgb(0, 0, 0);
  --white-color: rgb(255, 255, 255);
}

html,
body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 18px;
  min-height: 100%;
  display: grid;
  /*min-height: 100vh;*/
}

.small-disclaimer {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}

.bold {
  font-weight: bold;
}
.uppercase {
  text-transform: uppercase;
}
.text-left {
  text-align: left;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-items {
  align-items: center;
}
.gap-x-2 {
  column-gap: 8px;
}
.gap-x-4 {
  column-gap: 8px;
}
</style>
