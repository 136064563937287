<template>
  <div class="login">
    <div class="centeredOnMobile">
      <HeaderComponent id="header" :imagePosition="headerPosition" />
      <div>
        Use the personal token you have received from Ferrari to continue
      </div>
      <v-sheet id="sheet" class="mx-auto">
        <v-form ref="form" @submit.prevent>
          <v-text-field
            v-model="code"
            :rules="rules"
            label="Personal token"
            :append-inner-icon="codeVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append-inner="() => (codeVisible = !codeVisible)"
            :type="codeVisible ? 'password' : 'text'"
            autocomplete="on"
          >
          </v-text-field>
          <v-btn type="submit" block class="mt-2" @click="validate">
            Enter
          </v-btn>
        </v-form>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import Position from "../types/PositionEnum";

// TODO: ON LOGIN ENTER: CLEAR USER TOKEN IN STORE
// ON LOGIN COMPLETE: SET USER TOKEN TO STORE
// ROUTE GUARD: WATCH USER TOKEN, IF EMPTY or FORBIDDEN FROM BE - REDIRECT TO LOGIN PAGE

export default {
  name: "LoginView",
  components: {
    HeaderComponent,
  },
  data: () => ({
    code: "",
    codeVisible: true,
    headerPosition: Position.Middle,
    rules: [
      (value) => {
        if (value) return true;
        return "You must enter a token to continue.";
      },
    ],
  }),
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();

      valid && this.$router.push({ path: "landing" });
    },
  },
};
</script>

<style scoped>
#sheet {
  width: 50%;
}
.centeredOnMobile {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  #sheet {
    width: 100%;
  }
  #header {
    position: absolute;
    top: -100px;
  }

  .centeredOnMobile {
    position: relative;
  }
  .login {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
