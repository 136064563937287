<template>
  <div
    class="header"
    :class="{
      ifAlignedLeft: showImageAtBegin,
      ifAlignedRight: showImageAtEnd,
      ifAlignedMiddle: !title,
    }"
  >
    <v-btn
      v-if="this.showHome"
      id="home-button"
      @click="this.$router.push({ path: 'landing' })"
    >
      Home
    </v-btn>
    <div class="centered">
      <img
        class="main-logo"
        alt="Ferrari-group logo"
        src="../assets/logo.png"
      />
      <div v-if="title && showImageAtBegin" class="title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
import Position from "../types/PositionEnum";

export default {
  name: "HeaderComponent",
  props: {
    imagePosition: Position,
    title: String,
  },
  data() {
    return {
      showHome: this.$route.name !== "login" && this.$route.name !== "landing",
      showImageAtBegin: this.imagePosition === Position.Begin,
      showImageAtEnd: this.imagePosition === Position.End,
    };
  },
};
</script>

<style scoped>
#home-button {
  position: absolute;
  right: 18px;
}
.header {
  margin-bottom: 14px;
  width: 100%;
}

.header .centered {
  width: 80%;
  margin: 0 auto;
}

.header.ifAlignedLeft .centered {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.header.ifAlignedRight {
  display: flex;
  justify-content: end;
}

.header .main-logo {
  width: 250px;
}

.header .title {
  font-size: 24px;
  text-transform: uppercase;
  color: var(--primary-color);
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .header {
    padding: unset;
  }

  .header .main-logo {
    width: 120px;
  }

  .header.ifAlignedMiddle .main-logo {
    width: 150px;
  }

  .header .title {
    font-size: 18px;
    text-align: right;
  }
}
</style>
